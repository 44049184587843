import React, { Component } from 'react'
import axios from "axios"
import Swal from 'sweetalert2'

import "./contacto.scss"
import { FormContainer, checkableBoolProps, setInputProps } from '../common/forms/Form'
import { SperantService } from '../common/Http/SperantService'
import { HomeContactValidatonSchema, SchemaFormReferidos } from '../common/forms/constraints/ValidatonSchemas'
import Data from '../data/data'
import { withRouter } from 'react-router-dom'
import { projects } from '../../data/projects'
 class FormularioReferidos extends Component {
    constructor(props) {
        super(props)
        this.sperantService = new SperantService()
        this.submitForm = this.submitForm.bind(this)
        this.onSuccess = this.onSuccess.bind(this)
        this.onError = this.onError.bind(this)
    }
    onSuccess = _ =>{
        this.props.history.push(`/gracias${window.location.pathname}`)
    }
    onError = _ =>{
        Swal.fire({
            title: '',
            text: '¡Error! Intentelo más tarde',
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: 'red'
        })
    }
    async submitForm(values, { setSubmitting, resetForm }) {
       try {
        let utms = {}
        new URLSearchParams(localStorage.getItem("url_query"))
            .forEach((v, k) => /utm_/.test(k) && (utms[`${k.toLowerCase()}`] = v))
        
        const promo = this.props.promo.replace( /(<([^>]+)>)/ig, '')

        let client = {
            "fname": values.fname,
            "lname": values.lname,
            "email": values.email,
            "phone": values.telephone,
            "project_id": projects.get(values.project).sperant_id,
            "interest_type_id": 4,
            "input_channel_id": 4,
            "observation": [
                `Referente: ${values.owner_fname} ${values.owner_lname}`,
                values.owner_email,
                `tel: ${values.owner_telephone}`,
            ],
            ...utms
        }


        const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })

        axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
            "ss_id": "1gE-gi5sEibxdSTN9PqiCYjgZ2SwKJFnhGGQlKldOD34",
            "range": `Referidos!A:F`,
            "values": [[
                date, values.project, values.fname, values.lname, values.telephone, values.email,
                values.owner_fname, values.owner_lname, values.owner_email, values.owner_telephone,
                utms.utm_source, utms.utm_medium, utms.utm_campaign, utms.utm_content, utms.utm_term
            ]]
        }).catch(err => {
            console.error(err)
        })

        this.sperantService.createClient(client)
            .then(r=>{
                setSubmitting(false)
                window.fbq && window.fbq('track', 'Lead')
                this.onSuccess()
            })
            .catch(e=>{
                setSubmitting(false)
                this.onError()
            })

       } catch (err) {
        console.error(err)
       }
    }
    
    initialValues = {
        owner_fname: "",
        owner_lname: "",
        owner_email: "",
        owner_telephone: "",
        fname: "",
        lname: "",
        email: "",
        telephone: "",
        project: "",
        rooms_available: [],
        rooms_amount: "",
        time_of_day: "",
        terms: false
    }

    render() {
        return (
            <Data.Consumer>
            {context =>(
            <FormContainer
                initialValues={this.initialValues} validationSchema={SchemaFormReferidos} onSubmit={this.submitForm}
            >
                {form => {const {values, handleSubmit,errors} = form;
                
                return(
                <div className={"contacto "+this.props.page}>
                    <h2 className="title"><span className='bold'>TE ASESORAMOS</span> VÍA ONLINE</h2>
                    <p className="paragraph">Llena el formulario y un asesor se comunicará contigo.</p>
                    <form className="formulario" onSubmit={handleSubmit}>
                        <div className='flex flex-wrap mobile-mb-4'>
                            <span className='sub-title bold'>Tus datos</span>
                            <div className='flex w-full gap-4 mobile-flex-wrap mobile-gap-0'>
                                <input type="text" placeholder="Nombre*" {...setInputProps("owner_fname", "input w-1-2 mobile-w-full", form)}></input>
                                <input type="text" placeholder="Apellido*" {...setInputProps("owner_lname", "input w-1-2 mobile-w-full", form)}></input>
                            </div>
                            <div className='flex w-full gap-4 mobile-flex-wrap mobile-gap-0'>
                                <input type="phone" placeholder="Teléfono*" {...setInputProps("owner_telephone", "input w-1-2 mobile-w-full", form)}></input>
                                <input type="email" placeholder="Email*" {...setInputProps("owner_email", "input w-1-2 mobile-w-full", form)}></input>
                            </div>
                        </div>
                        <div className='w-full'>
                            <span className='flex sub-title bold w-full'>Datos del Referido</span>
                            <div className='flex w-full gap-4 mobile-flex-wrap mobile-gap-0'>
                                <input type="text" placeholder="Nombre*" {...setInputProps("fname", "input col", form)}></input>
                                <input type="text" placeholder="Apellido*" {...setInputProps("lname", "input", form)}></input>
                            </div>
                            <input type="email" placeholder="E-mail*" {...setInputProps("email", "input important-w-full", form)}></input>
                            <div className='flex w-full gap-4 mobile-flex-wrap mobile-gap-0'>
                                <input type="tel" placeholder="Teléfono*" {...setInputProps("telephone", "input ", form)}></input>
                                <select {...setInputProps("project", "input col", form)}>
                                    <option selected disabled value="1">Proyecto*</option>
                                    {this.props?.options.map((project, index) =>
                                        <option key={index} value={project.text}>{project.text}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="flex-column button-radius">
                            <div className="radio flex-row">
                                <input type="checkbox" id="terminos" {...checkableBoolProps("terms", "boton-radio", form)}></input>
                                <label className="button-title flex-row" for="terminos"></label>
                                <div className="radius" id="link-terminos"></div> Estoy de acuerdo con las <a className="d-block terminos pointer" href="/politicas-de-privacidad" target="_blank"> políticas de privacidad</a><br></br>
                            </div>
                        </div>
                        <div className="btn"><button type="submit" className="btn-submit btn-radio" disabled={form.isSubmitting}>{form.isSubmitting ? "Enviando" : "Enviar"}</button></div>
                    </form>
                </div>)}}
            </FormContainer>
            )}
            </Data.Consumer>
        )
    }
}
export default withRouter(FormularioReferidos)